<template>
    <div style="text-align:center;">
        <b>{{ sms_dispo }}</b> SMS Disponibles<br/>
        <small><a :href="'https://app.studioconnect.fr/php/sms/buysms.php?uid='+uid" target="_blanck">Acheter des SMS</a></small>
    </div>
</template>

<style lang="scss">
</style>

<script>


export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            sms_dispo:0,
            uid:''
        }
    },
    watch:{
        $route (){
            this.reload()
        },
    },
    beforeMount:function(){
        this.reload()
    },
    methods:{
        reload()
        {
            this.uid = this.$srvApi.getStudio()

            this.$srvApi.Req('get', 'studio')
            .then(( rep )=>{
                //console.log( rep );
                this.sms_dispo = rep.data[0].sms_max;
            })
        }
    }
}

</script>